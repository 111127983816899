@import "@/styles/_variables.scss";



















































































































































































































































































































































































































  .new-btn {
    padding: 0 20px;
  }
table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}
  
